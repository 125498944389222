import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часті запитання на позачасових заходах
			</title>
			<meta name={"description"} content={"Відповіді, які допоможуть вам ідеально спланувати"} />
			<meta property={"og:title"} content={"FAQ | Часті запитання на позачасових заходах"} />
			<meta property={"og:description"} content={"Відповіді, які допоможуть вам ідеально спланувати"} />
			<meta property={"og:image"} content={"https://blumoran.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text
				margin="0px 0px 10px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				sm-margin="0px 0px 50px 0px"
				text-align="center"
			>
				Відповіді на поширені запитання
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
				color="#555a5f"
				padding="0px 0px 0px 0px"
				md-width="100%"
			>
				У Timeless Events ми віримо в те, що зробимо процес планування вашого заходу максимально зрозумілим і простим. Ця сторінка поширених запитань призначена для того, щоб відповісти на деякі з найпоширеніших запитань наших клієнтів. Якщо ви не знайшли відповіді на своє питання, ми рекомендуємо вам зв'язатися з нами напряму - ми тут, щоб допомогти!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						На яких типах заходів ви спеціалізуєтесь?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Timeless Events має все необхідне для проведення широкого спектру заходів, включаючи весілля, корпоративні збори та приватні вечірки. Кожна подія пристосована для задоволення унікальних потреб і бажань наших клієнтів.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи можете ви організовувати заходи за межами традиційних місць проведення?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Безумовно! Незалежно від того, чи це захід на відкритому повітрі, чи унікальна локація, ми маємо досвід управління логістикою та налаштуванням в різних умовах, забезпечуючи бездоганний досвід.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Що входить до вашого повного пакету послуг з планування заходів?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Наш пакет послуг включає вибір місця проведення заходу, організацію кейтерингу, декор та дизайн, організацію розваг та повну координацію впродовж усього дня.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						За скільки часу потрібно бронювати захід?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ми рекомендуємо бронювати якомога раніше, в ідеалі - за 6-12 місяців, щоб гарантувати наявність бажаних дат і послуг.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи надаєте ви послуги з урахуванням дієтичних обмежень та уподобань?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми тісно співпрацюємо з постачальниками, які можуть задовольнити різноманітні дієтичні потреби, включаючи вегетаріанські, веганські, безглютенові та алергічні страви.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Яка ваша політика скасування бронювання?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Наша політика скасування замовлень розроблена таким чином, щоб бути прозорою та справедливою. Конкретні деталі можуть відрізнятися залежно від характеру послуг, тому ми рекомендуємо обговорити їх під час первинної консультації.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});